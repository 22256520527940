import { createRouter, createWebHistory } from 'vue-router'
import { uzr } from '@/user/User';

let loguser = uzr.currentUser()
var perm = {}

if (loguser) {
    perm = loguser.perm
   console.log(perm)
}

const routes = [
    {
        path: '/',
        redirect: 'dashboard',
        component: () => import('@/layouts/DashboardLayout.vue'),
        meta: { requiresAuth: true, },
        children: [{
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('@/views/dashBoard.vue'),
            meta: { has_perm: true, },
        },
        {
            path: '/userprofile',
            name: 'user-profile',
            component: () => import('@/views/UserProfile.vue'),
            meta: { has_perm: true, },
        },
        {
            path: '/users/add',
            name: 'new-user',
            component: () => import('@/views/hyzadmin/users/userAdd.vue'),
            meta: { has_perm: perm.c_a_user },
        },
        {
            path: '/users/list',
            name: 'user-list',
            component: () => import('@/views/hyzadmin/users/userList.vue'),
            meta: { has_perm: perm.c_v_user },
        },
        {
            path: '/user/view/:userid',
            name: 'user-view',
            component: () => import('@/views/hyzadmin/users/userView.vue'),
            meta: { has_perm: perm.c_v_user, },
        },
        {
            path: '/user-admin/edit/:useradmid',
            name: 'edit-user-admin',
            component: () => import('@/views/hyzadmin/users/editUserAdmin.vue'),
            meta: { has_perm: perm.c_e_user, },
        },
        {
            path: '/user/edit/:userdetid',
            name: 'user-edit',
            component: () => import('@/views/hyzadmin/users/editUser.vue'),
            meta: { has_perm: perm.c_e_user, },
        },
        {
            path: '/assign-packing-partner-or-executive/list',
            name: 'assign-list',
            component: () => import('@/views/hyzadmin/users/assignmentList.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/assign-packing-partner-or-executive/view/:assignid',
            name: 'assign-view',
            component: () => import('@/views/hyzadmin/users/assignmentView.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/assign-packing-partner-or-executive/edit/:assignid',
            name: 'assign-edit',
            component: () => import('@/views/hyzadmin/users/assignmentEdit.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/outlet/packing-partner/list',
            name: 'out-pp-list',
            component: () => import('@/views/outlet/packingPartner/ppList.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/factory/all-outlets/list',
            name: 'all-outlets',
            component: () => import('@/views/factory/outlet/outletList.vue'),
            meta: { has_perm: perm.c_v_outlet  },
        },
        {
            path: '/factory/outlets/view/:outid',
            name: 'view-out',
            component: () => import('@/views/factory/outlet/viewOutlet.vue'),
            meta: { has_perm: perm.c_v_outlet },
        },
        {
            path: '/factory/packing-partner/add/products',
            name: 'assign-prod-pp',
            component: () => import('@/views/factory/packingpartner/assignProducts.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/packing-partner/list/products',
            name: 'pp-prod-list',
            component: () => import('@/views/packingPartner/listProducts.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/factory/assign-packets-to-packing-partner',
            name: 'assign-pack',
            component: () => import('@/views/factory/outlet/assignPacketsToOutlets.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/outlet/packet-list',
            name: 'out-packet-list',
            component: () => import('@/views/outlet/packetSticker/packetList.vue'),
            meta: { has_perm: true },
        },

        {
            path: '/factory/passigned-packet-sticker-list',
            name: 'assign-pack-list',
            component: () => import('@/views/factory/outlet/assignedPacketStickerList.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/outlet/assign-packets-packing-partner',
            name: 'out-assign-pack',
            component: () => import('@/views/outlet/packetSticker/assignPacketsToPP.vue'),
            meta: { has_perm: true },
        },




        {
            path: '/executive/tool-sprayer-list',
            name: 'exe-toolsp-list',
            component: () => import('@/views/executive/products/toolSprayersList.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/executive/pots-list',
            name: 'exe-pots-list',
            component: () => import('@/views/executive/products/potsList.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/executive/growbag-list',
            name: 'exe-bags-list',
            component: () => import('@/views/executive/products/growbagList.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/executive/products-list',
            name: 'exe-prod-list',
            component: () => import('@/views/executive/products/productsList.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/executive/add/shop',
            name: 'exe-add-shop',
            component: () => import('@/views/executive/shops/addShop.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/executive/view/shop/:shopid',
            name: 'exe-view-shop',
            component: () => import('@/views/executive/shops/viewShop.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/executive/list/shop',
            name: 'exe-list-shop',
            component: () => import('@/views/executive/shops/listShop.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/executive/edit/shop/:shopid',
            name: 'exe-edit-shop',
            component: () => import('@/views/executive/shops/editShop.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/executive/add/order',
            name: 'exe-add-order',
            component: () => import('@/views/executive/orders/addOrder.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/executive/order/list',
            name: 'exe-list-order',
            component: () => import('@/views/executive/orders/listOrder.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/executive/order/view/:orderid',
            name: 'exe-view-order',
            component: () => import('@/views/executive/orders/viewOrder.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/outlet/active-order/list',
            name: 'out-list-pen-order',
            component: () => import('@/views/outlet/orders/pendingOrderList.vue'),
            meta: { has_perm: perm.c_m_out_exe_order, },
        },
        
        {
            path: '/outlet/order/view/:orderid',
            name: 'out-view-order',
            component: () => import('@/views/outlet/orders/pendingOrderView.vue'),
            meta: { has_perm: perm.c_m_out_exe_order },
        },
        {
            path: '/outlet/order-request/add',
            name: 'out-order-request',
            component: () => import('@/views/outlet/orderRequest/orderRequesttoFactory.vue'),
            meta: { has_perm: perm.c_m_out_ord_req },
        },
        
        {
            path: '/outlet/order-request/list',
            name: 'out-order-request-list',
            component: () => import('@/views/outlet/orderRequest/orderRequestList.vue'),
            meta: { has_perm: perm.c_m_out_ord_req },
        },
        {
            path: '/outlet/order-request/order/view/:orderreqid',
            name: 'out-view-orderreq',
            component: () => import('@/views/outlet/orderRequest/orderRequestView.vue'),
            meta: { has_perm: perm.c_m_out_ord_req },
        },
        {
            path: '/factory/order-request/list',
            name: 'fac-out-pending-order',
            component: () => import('@/views/factory/outlet/requestOrder/facrequestOrderList.vue'),
            meta: { has_perm: perm.c_m_out_ord },
        },
        {
            path: '/factory/order-request/view/:orderreqid',
            name: 'fac-out-pending-order-view',
            component: () => import('@/views/factory/outlet/requestOrder/facrequestOrderView.vue'),
            meta: { has_perm: perm.c_m_out_ord  },
        },
        {
            path: '/factory/order-request/cancelled-order/list',
            name: 'fac-out-cancel-order',
            component: () => import('@/views/factory/outlet/requestOrder/cancelledOrderList.vue'),
            meta: { has_perm: perm.c_m_out_ord },
        },
        {
            path: '/factory/order-request/approved-order/list',
            name: 'fac-out-appr-order',
            component: () => import('@/views/factory/outlet/requestOrder/approvedOrderList.vue'),
            meta: { has_perm: perm.c_m_out_ord },
        },
        {
            path: '/outlet/order-request/approved-order/list',
            name: 'out-order-requ-appr-list',
            component: () => import('@/views/outlet/orderRequest/approvedList.vue'),
            meta: { has_perm: perm.c_m_out_ord_req },
        },
        {
            path: '/outlet/order-received/:orderreqid',
            name: 'out-receive-order',
            component: () => import('@/views/outlet/orderRequest/addreceivedOrder.vue'),
            meta: { has_perm: perm.c_m_out_ord_req },
        },
        {
            path: '/outlet/order-deliver/list',
            name: 'out-deliver-order',
            component: () => import('@/views/outlet/orderRequest/deliveredOrderList.vue'),
            meta: { has_perm: perm.c_m_out_ord_req },
        },
        {
            path: '/outlet/order-cancel/list',
            name: 'out-cancel-order',
            component: () => import('@/views/outlet/orderRequest/cancelledOrderList.vue'),
            meta: { has_perm: perm.c_m_out_ord_req },
        },
        
        {
            path: '/factory/order-request/delivered-order/list',
            name: 'fac-out-del-order',
            component: () => import('@/views/factory/outlet/requestOrder/deliveredOrderList.vue'),
            meta: { has_perm: perm.c_m_out_ord },
        },
        {
            path: '/outlet/executive-order/approved-order/list',
            name: 'out-exe-appr-order',
            component: () => import('@/views/outlet/orders/approvedOrderList.vue'),
            meta: { has_perm: perm.c_m_out_exe_order },
        },
        {
            path: '/outlet/invoice-generate',
            name: 'out-inv-gen',
            component: () => import('@/views/outlet/orders/invoiceGenerate.vue'),
            meta: { has_perm: perm.c_m_out_exe_order },
        },
        {
            path: '/outlet/manage-shops',
            name: 'out-shops',
            component: () => import('@/views/outlet/orders/manageShops.vue'),
            meta: { has_perm: perm.c_m_out_exe_order },
        },
        {
            path: '/outlet/shops/:shopid',
            name: 'out-view-shop',
            component: () => import('@/views/outlet/orders/viewShops.vue'),
            meta: { has_perm: perm.c_m_out_exe_order },
        },

        {
            path: '/factory/payments',
            name: 'adm-manag-pay-menu',
            component: () => import('@/views/menupages/admPaymentMenu.vue'),
            meta: { has_perm: perm.c_v_inv },
        },
        {
            path: '/factory/all-payments',
            name: 'adm-all-pay-menu',
            component: () => import('@/views/menupages/admAllPaymentsMenu.vue'),
            meta: { has_perm: perm.c_v_inv },
        },

        {
            path: '/factory/fact-payments/list/:godownid',
            name: 'adm-manag-pay-list',
            component: () => import('@/views/factory/payments/admManagePaymentsList.vue'),
            meta: { has_perm: perm.c_v_inv },
        },
        {
            path: '/factory/all-fact-payments/list/:godownid',
            name: 'adm-all-pay-list',
            component: () => import('@/views/factory/payments/admAllFactPaymentsList.vue'),
            meta: { has_perm: perm.c_v_inv },
        },


       
        {
            path: '/outlet/executive-order/cancelled-order/list',
            name: 'out-exe-cancel-order',
            component: () => import('@/views/outlet/orders/cancelledOrderList.vue'),
            meta: { has_perm: perm.c_m_out_exe_order },
        },
        {
            path: '/executive/executive-received-order/:orderid',
            name: 'exe-rec-order',
            component: () => import('@/views/executive/orders/exeReceivedOrderAdd.vue'),
            meta: { has_perm: true },
        },
        {
            path: '/outlet/executive-order/delivered-order/list',
            name: 'out-exe-del-order',
            component: () => import('@/views/outlet/orders/deliveredOrderList.vue'),
            meta: { has_perm: perm.c_m_out_exe_order },
        },
        {
            path: '/outlet/stock/product-stock/list',
            name: 'out-prod-stock',
            component: () => import('@/views/outlet/stock/productStockList.vue'),
            meta: { has_perm: perm.c_m_out_prod },
        },
       
        {
            path: '/add-inventory/',
            name: 'fac-add-inv',
            component: () => import('@/views/factory/inventory/addInventory.vue'),
            meta: { has_perm: perm.c_a_inv },
        },
        {
            path: '/inventory/list',
            name: 'fac-inv-list',
            component: () => import('@/views/factory/inventory/listInventory.vue'),
            meta: { has_perm: perm.c_v_inv },
        },
        {
            path: '/outlet/agrocops/list',
            name: 'out-agro-list',
            component: () => import('@/views/outlet/agrocops/orderList.vue'),
            meta: { has_perm: perm.c_m_agro },
        },
        {
            path: '/outlet/agrocops/sale-list',
            name: 'out-agro-sale-list',
            component: () => import('@/views/outlet/agrocops/manageAgroSaleList.vue'),
            meta: { has_perm: perm.c_m_agro },
        },
        {
            path: '/outlet/agrocops/add-sale',
            name: 'out-agro-add',
            component: () => import('@/views/outlet/agrocops/addAgrocopsSale.vue'),
            meta: { has_perm: perm.c_m_agro },
        },

        {
            path: '/outlet/delivery-note/:deliverynoteid',
            name: 'out-del-note',
            component: () => import('@/views/outlet/agrocops/deliveryNote.vue'),
            meta: { has_perm: perm.c_m_agro, },
        },
        {
            path: '/inventory-list/:godownid',
            name: 'gdn-adm-inv-list',
            component: () => import('@/views/factory/inventory/admListInventory.vue'),
            meta: { has_perm: perm.c_v_inv },
        },
        // {
        //     path: '/inventory/invoice/:invoiceid',
        //     name: 'fact-inv',
        //     component: () => import('@/views/factory/inventory/generateInvoice.vue'),
        //     meta: { has_perm: true, },
        // },
        {
            path: '/inventory/invoice/:invoiceid',
            name: 'fact-pre-inv',
            component: () => import('@/views/factory/inventory/preInvoicegenerate.vue'),
            meta: { has_perm: perm.c_v_inv, },
        },
        {
            path: '/outlet/agrocops-order-received/:orderid',
            name: 'out-agro-order',
            component: () => import('@/views/outlet/agrocops/addAgrocopsOrderReceived.vue'),
            meta: { has_perm: perm.c_m_agro, },
        },
        {
            path: '/outlet/add-inventory',
            name: 'out-add-inv',
            component: () => import('@/views/outlet/inventory/addOutletInventory.vue'),
            meta: { has_perm: perm.c_m_d_s, },
        },
        {
            path: '/outlet/list-inventory',
            name: 'out-list-inv',
            component: () => import('@/views/outlet/inventory/listOutletInventory.vue'),
            meta: { has_perm: perm.c_m_d_s, },
        },
        {
            path: '/outlet/generate-invoice/:invoiceid',
            name: 'out-pre-inv',
            component: () => import('@/views/outlet/inventory/outletInvoiceGenerate.vue'),
            meta: { has_perm: perm.c_m_d_s, },
        },
        {
            path: '/outlet/agrocops-order-received/list',
            name: 'out-agro-rec-order-list',
            component: () => import('@/views/outlet/agrocops/receivedAgrocopsOutletList.vue'),
            meta: { has_perm: perm.c_m_agro, },
        },
        {
            path: '/outlet/agrocops-order-delivered/list',
            name: 'out-agro-del-order-list',
            component: () => import('@/views/outlet/agrocops/deliveredToAgrocopsOutletList.vue'),
            meta: { has_perm: perm.c_m_agro, },
        },
       
        {
            path: '/outlet/agrocops-product-stock/list',
            name: 'out-agro-prod-list',
            component: () => import('@/views/outlet/agrocops/stocks/agroProductStockList.vue'),
            meta: { has_perm: perm.c_m_agro, },
        },
        {
            path: '/outlet/agrocops-tools-sprayers-stock/list',
            name: 'out-agro-tools-list',
            component: () => import('@/views/outlet/agrocops/stocks/agroToolStockList.vue'),
            meta: { has_perm: perm.c_m_agro, },
        },
        {
            path: '/outlet/agrocops-pots-stock/list',
            name: 'out-agro-pot-stock',
            component: () => import('@/views/outlet/agrocops/stocks/agroPotStockList.vue'),
            meta: { has_perm: perm.c_m_agro, },
        },
        {
            path: '/outlet/agrocops-growbag-stock/list',
            name: 'out-agro-bag-stock',
            component: () => import('@/views/outlet/agrocops/stocks/agroGrowbagStockList.vue'),
            meta: { has_perm: perm.c_m_agro, },
        },

       

        
        {
            path: '/md-department-address/add',
            name: 'md-dept',
            component: () => import('@/views/masterdata/deptAddressAdd.vue'),
            meta: { has_perm: perm.c_v_md },
        },
        {
            path: '/outlet/tools-sprayers-list',
            name: 'out-tool-stock',
            component: () => import('@/views/outlet/stock/toolsStockList.vue'),
            meta: { has_perm: perm.c_m_out_prod },
        },
        {
            path: '/outlet/pots-list',
            name: 'out-pot-stock',
            component: () => import('@/views/outlet/stock/potStockList.vue'),
            meta: { has_perm: perm.c_m_out_prod },
        },
        {
            path: '/outlet/growbags-list',
            name: 'out-bag-stock',
            component: () => import('@/views/outlet/stock/growbagStockList.vue'),
            meta: { has_perm: perm.c_m_out_prod },
        },
        {
            path: '/admin-user/add',
            name: 'hyz-adm-user-add',
            component: () => import('@/views/hyzadmin/adminusers/addAdminUsers.vue'),
            meta: { has_perm: perm.c_a_admin },
        },
        {
            path: '/admin-user/edit/:staffid',
            name: 'hyz-adm-user-edit',
            component: () => import('@/views/hyzadmin/adminusers/editAdminUsers.vue'),
            meta: { has_perm: perm.c_e_admin },
        },
        {
            path: '/admin-user/list',
            name: 'hyz-adm-user-list',
            component: () => import('@/views/hyzadmin/adminusers/listAdminUsers.vue'),
            meta: { has_perm: perm.c_v_admin },
        },
        {
            path: '/admin-user/view/:staffid',
            name: 'hyz-adm-user-view',
            component: () => import('@/views/hyzadmin/adminusers/viewAdminUsers.vue'),
            meta: { has_perm: perm.c_v_admin },
        },
        {
            path: '/factory-admin-user/add',
            name: 'fact-adm-user-add',
            component: () => import('@/views/factory/factoryadminusers/addFactoryAdminUsers.vue'),
            meta: { has_perm: perm.c_a_fact_admin },
        },
        {
            path: '/factory-admin-user/view/:staffid',
            name: 'fact-adm-user-view',
            component: () => import('@/views/factory/factoryadminusers/viewFactoryAdminUsers.vue'),
            meta: { has_perm: perm.c_v_fact_admin },
        },
        {
            path: '/factory-admin-user/list',
            name: 'fact-adm-user-list',
            component: () => import('@/views/factory/factoryadminusers/listFactoryAdminUsers.vue'),
            meta: { has_perm: perm.c_v_fact_admin },
        },
        {
            path: '/factory-admin-user/edit/:staffid',
            name: 'fact-adm-user-edit',
            component: () => import('@/views/factory/factoryadminusers/editFactoryAdminUsers.vue'),
            meta: { has_perm: perm.c_e_fact_admin },
        },
        {
            path: '/outlet-roles/add',
            name: 'new-out-role',
            component: () => import('@/views/outlet/roles/outRoleAdd.vue'),
            meta: { has_perm: perm.c_a_out_role },
        },
        {
            path: '/outlet-roles',
            name: 'outletroles',
            component: () => import('@/views/outlet/roles/outRoleList.vue'),
            meta: { has_perm: perm.c_v_out_role },
        },
        {
            path: '/outlet-roles/edit/:roleid',
            name: 'edit-out-role',
            component: () => import('@/views/outlet/roles/outRoleEdit.vue'),
            meta: { has_perm: perm.c_e_out_role  },
        },
        {
            path: '/outlet-admin-user/add',
            name: 'out-adm-user-add',
            component: () => import('@/views/outlet/outadminusers/addOutAdminUsers.vue'),
            meta: { has_perm: perm.c_a_out_admin },
        },
        {
            path: '/outlet-admin-user/view/:staffid',
            name: 'out-adm-user-view',
            component: () => import('@/views/outlet/outadminusers/viewOutAdminUsers.vue'),
            meta: { has_perm: perm.c_v_out_admin },
        },
        {
            path: '/outlet-admin-user/list',
            name: 'out-adm-user-list',
            component: () => import('@/views/outlet/outadminusers/listOutAdminUsers.vue'),
            meta: { has_perm: perm.c_v_out_admin },
        },
        {
            path: '/outlet-admin-user/edit/:staffid',
            name: 'out-adm-user-edit',
            component: () => import('@/views/outlet/outadminusers/editOutAdminUsers.vue'),
            meta: { has_perm: perm.c_e_out_admin },
        },
        {
            path: '/factory-generate-invoice',
            name: 'fac-gen-inv',
            component: () => import('@/views/factory/outlet/requestOrder/generateInvoiceReq.vue'),
            meta: { has_perm: perm.c_m_out_ord },
        },
        {
            path: '/factory/payments/add',
            name: 'fact-add-pay',
            component: () => import('@/views/factory/payments/addFactPayment.vue'),
            meta: { has_perm: perm.c_a_inv },
        },
        {
            path: '/factory/payments/list',
            name: 'fact-manag-pay',
            component: () => import('@/views/factory/payments/managePayments.vue'),
            meta: { has_perm: perm.c_v_inv },
        },
        {
            path: '/factory/complete-payments/list',
            name: 'fact-all-pay',
            component: () => import('@/views/factory/payments/allFactPayments.vue'),
            meta: { has_perm: perm.c_v_inv },
        },
        {
            path: '/outlet/payments/add',
            name: 'out-add-pay',
            component: () => import('@/views/outlet/payments/addOutletPayments.vue'),
            meta: { has_perm: perm.c_m_d_s },
        },
        {
            path: '/outlet/payments/list',
            name: 'out-manag-pay',
            component: () => import('@/views/outlet/payments/manageOutPayments.vue'),
            meta: { has_perm: perm.c_m_d_s },
        },
        {
            path: '/outlet/complete-payments/list',
            name: 'out-all-pay',
            component: () => import('@/views/outlet/payments/allOutletPayments.vue'),
            meta: { has_perm: perm.c_m_d_s },
        },

       
        









        {
            path: '/md-raw-product/add',
            name: 'md-rawproduct',
            component: () => import('@/views/masterdata/rawproductAdd.vue'),
            meta: { has_perm: perm.c_v_md, },
        },
        {
            path: '/md-process/add',
            name: 'md-process',
            component: () => import('@/views/masterdata/processAdd.vue'),
            meta: { has_perm: perm.c_v_md, },
        },
        {
            path: '/raw-material/add',
            name: 'md-rawmaterial',
            component: () => import('@/views/masterdata/rawmaterialAdd.vue'),
            meta: { has_perm: perm.c_v_md, },
        },
        {
            path: '/md-product/add',
            name: 'md-product',
            component: () => import('@/views/masterdata/productAdd.vue'),
            meta: { has_perm: perm.c_v_md, },
        },
        {
            path: '/godown/add',
            name: 'new-godown',
            component: () => import('@/views/godown/godownAdd.vue'),
            meta: { has_perm: perm.c_a_admin },
        },
        {
            path: '/godown/view/:godownid',
            name: 'view-godown',
            component: () => import('@/views/godown/godownView.vue'),
            meta: { has_perm: perm.c_v_admin },
        },
        {
            path: '/godown/list',
            name: 'list-godown',
            component: () => import('@/views/godown/godownList.vue'),
            meta: { has_perm: perm.c_v_admin },
        },
        {
            path: '/godown/edit/:godownid',
            name: 'edit-godown',
            component: () => import('@/views/godown/godownEdit.vue'),
            meta: { has_perm: perm.c_e_admin },
        },
        {
            path: '/rawmaterial-stock/add',
            name: 'rm-stock',
            component: () => import('@/views/factory/rawmaterialstock/rawmaterialstockAdd.vue'),
            meta: { has_perm: perm.c_a_rm },
        },
        {
            path: '/rawmaterial-stock/list',
            name: 'rm-list-stock',
            component: () => import('@/views/factory/rawmaterialstock/rawmaterialstockList.vue'),
            meta: { has_perm: perm.c_v_rm },
        },
        {
            path: '/process/add',
            name: 'process',
            component: () => import('@/views/factory/process/processAdd.vue'),
            meta: { has_perm: perm.c_a_prs },
        },
        {
            path: '/raw-product/add',
            name: 'pro-stock',
            component: () => import('@/views/factory/rawproduct/rawproductAdd.vue'),
            meta: { has_perm: perm.c_a_rp },
        },
        {
            path: '/raw-product/list',
            name: 'pro-list-stock',
            component: () => import('@/views/factory/rawproduct/rawproductList.vue'),
            meta: { has_perm: perm.c_v_rp },
        },
        {
            path: '/raw-material/list',
            name: 'rm-total-stock',
            component: () => import('@/views/factory/rawmaterialstock/rmtotalList.vue'),
            meta: { has_perm: perm.c_v_rm },
        },
        {
            path: '/process-raw-material',
            name: 'process-rm',
            component: () => import('@/views/factory/process/processrawmaterialAdd.vue'),
            meta: { has_perm: perm.c_v_prs  },
        },

        {
            path: '/raw-material',
            name: 'adm-rm',
            component: () => import('@/views/menupages/rawAdminList.vue'),
            meta: { has_perm: perm.c_v_rm },
        },
        {
            path: '/process',
            name: 'process-list',
            component: () => import('@/views/factory/process/processList.vue'),
            meta: { has_perm: perm.c_v_prs },
        },    

        {
            path: '/godown-rm-stock-list/:godownid',
            name: 'gdn-adm-rm-st-list',
            component: () => import('@/views/factory/rawmaterialstock/admrmstockList.vue'),
            meta: { has_perm: perm.c_v_rm },
        },
        {
            path: '/godown-rm-total-stock-list/:godownid',
            name: 'gdn-adm-rm-tot-st-list',
            component: () => import('@/views/factory/rawmaterialstock/rawadmintotalstockList.vue'),
            meta: { has_perm: perm.c_v_rm },
        },
        {
            path: '/raw-material/total-stock',
            name: 'adm-rm-total-stock',
            component: () => import('@/views/menupages/rawadminTStockmenu.vue'),
            meta: { has_perm: perm.c_v_rm },
        },
        {
            path: '/md-packet/add',
            name: 'md-packet',
            component: () => import('@/views/masterdata/packetAdd.vue'),
            meta: { has_perm: perm.c_v_md },
        },
        {
            path: '/md-tools-sprayers/add',
            name: 'md-tools',
            component: () => import('@/views/masterdata/toolsSprayers.vue'),
            meta: { has_perm: perm.c_v_md },
        },
       
        
        {
            path: '/packet/add',
            name: 'packet-add',
            component: () => import('@/views/factory/packets/packetAdd.vue'),
            meta: { has_perm: perm.c_a_pks },
        },
        {
            path: '/packet-menu',
            name: 'adm-packet',
            component: () => import('@/views/menupages/packetAdminmenu.vue'),
            meta: { has_perm: perm.c_v_pks },
        },
        {
            path: '/packet-list/:godownid',
            name: 'packet-adm-list',
            component: () => import('@/views/factory/packets/packetadmstockList.vue'),
            meta: { has_perm: perm.c_v_pks },
        },
        {
            path: '/packet-stock-list',
            name: 'packet-list',
            component: () => import('@/views/factory/packets/packetstockList.vue'),
            meta: { has_perm: perm.c_v_pks },
        },
        {
            path: '/total-packet-godown-stock',
            name: 'adm-total-packet-menu',
            component: () => import('@/views/menupages/totalpacketAdminmenu.vue'),
            meta: { has_perm: perm.c_v_pks },
        },
        {
            path: '/total-packet-stock/list',
            name: 'total-packet-stock',
            component: () => import('@/views/factory/packets/totalpacketstockList.vue'),
            meta: { has_perm: perm.c_v_pks },
        },
        {
            path: '/total-packet-stock/:godownid',
            name: 'adm-total-packet-stock',
            component: () => import('@/views/factory/packets/totalpacketstockadmList.vue'),
            meta: { has_perm: perm.c_v_pks },
        },
        {
            path: '/product/add',
            name: 'product-add',
            component: () => import('@/views/factory/product/productAdd.vue'),
            meta: { has_perm: perm.c_a_p },
        },
        {
            path: '/product-list/',
            name: 'product-list',
            component: () => import('@/views/factory/product/productList.vue'),
            meta: { has_perm: perm.c_v_p },
        },
        {
            path: '/godown-product-list/',
            name: 'adm-product-list-menu',
            component: () => import('@/views/menupages/productstockAdminmenu.vue'),
            meta: { has_perm: perm.c_v_p },
        },
        {
            path: '/godown-product-stock-list/:godownid',
            name: 'adm-product-list',
            component: () => import('@/views/factory/product/admproductstockList.vue'),
            meta: { has_perm: perm.c_v_p },
        },
        {
            path: '/godown-product/list/:godownid',
            name: 'adm-tot-pro-stock',
            component: () => import('@/views/factory/product/admtotalproductstockList.vue'),
            meta: { has_perm: perm.c_v_p },
        },
        {
            path: '/total-product-list',
            name: 'tot-pro-stock',
            component: () => import('@/views/factory/product/totalproductList.vue'),
            meta: { has_perm: perm.c_v_p },
        },
        {
            path: '/godown-raw-product/list',
            name: 'adm-rawpro-list-stock',
            component: () => import('@/views/menupages/rawproductAdminmenu.vue'),
            meta: { has_perm: perm.c_v_rp },
        },
        {
            path: '/raw-product/:godownid',
            name: 'adm-rawp-stock',
            component: () => import('@/views/factory/rawproduct/adminrawproductStockList.vue'),
            meta: { has_perm: perm.c_v_rp },
        },
        {
            path: '/godown-product/list',
            name: 'adm-tot-pro-list-stock',
            component: () => import('@/views/menupages/productTotalStockAdminMenu.vue'),
            meta: { has_perm: perm.c_v_p },
        },
        
        {
            path: '/adm-raw-product-list',
            name: 'tot-adm-raw-pro-menu',
            component: () => import('@/views/menupages/rawtotalproductAdminMenu.vue'),
            meta: { has_perm: perm.c_v_rp },
        },
        {
            path: '/adm-raw-product-list/:godownid',
            name: 'tot-adm-raw-pro-stock',
            component: () => import('@/views/factory/rawproduct/admrawproductTotalList.vue'),
            meta: { has_perm: perm.c_v_rp },
        },
        {
            path: '/hyzroles',
            name: 'hyzroles',
            component: () => import('@/views/hyzadmin/roles/roleList.vue'),
            meta: { has_perm: perm.c_v_role },
        },
        {
            path: '/hyzroles/add',
            name: 'new-hyzrole',
            component: () => import('@/views/hyzadmin/roles/roleAdd.vue'),
            meta: { has_perm: perm.c_a_role },
        },

        {
            path: '/factoryroles/add',
            name: 'new-factrole',
            component: () => import('@/views/factory/factoryroles/factoryRoleAdd.vue'),
            meta: { has_perm: perm.c_a_fact_role },
        },
        {
            path: '/factoryroles',
            name: 'factoryroles',
            component: () => import('@/views/factory/factoryroles/factoryRoleList.vue'),
            meta: { has_perm: perm.c_v_fact_role },
        },
        {
            path: '/factory-roles/edit/:roleid',
            name: 'edit-factory-role',
            component: () => import('@/views/factory/factoryroles/factoryRoleEdit.vue'),
            meta: { has_perm: perm.c_e_fact_role },
        },
        {
            path: '/hyzroles/edit/:roleid',
            name: 'edit-hyz-role',
            component: () => import('@/views/hyzadmin/roles/roleEdit.vue'),
            meta: { has_perm: perm.c_e_role },
        },
        {
            path: '/raw-material/edit/:rmsid',
            name: 'edit-rm',
            component: () => import('@/views/factory/rawmaterialstock/editrmStock.vue'),
            meta: { has_perm: perm.c_e_rm },
        },
        {
            path: '/raw-product/edit/:rawp_id',
            name: 'edit-raw-product',
            component: () => import('@/views/factory/rawproduct/editRawProduct.vue'),
            meta: { has_perm: perm.c_e_rp },
        },
        {
            path: '/raw-product/stock-list',
            name: 'st-raw-product',
            component: () => import('@/views/factory/rawproduct/rawproductTotalList.vue'),
            meta: { has_perm: perm.c_v_rp },
        },
        {
            path: '/packet-edit/:packid',
            name: 'edit-pack',
            component: () => import('@/views/factory/packets/packetEdit.vue'),
            meta: { has_perm: perm.c_e_pks },
        },

        // {
        //     path: '/packet-partner/add',
        //     name: 'add-pp',
        //     component: () => import('@/views/packingPartner/addPartner.vue'),
        //     meta: { has_perm: perm.c_a_pp },
        // },
        // {
        //     path: '/packet-partner/list',
        //     name: 'pp-list',
        //     component: () => import('@/views/packingPartner/listPartner.vue'),
        //     meta: { has_perm: perm.c_v_pp },
        // },
        {
            path: '/packet-partner/admin',
            name: 'pp-adm-menu',
            component: () => import('@/views/menupages/ppAdminMenu.vue'),
            meta: { has_perm: perm.c_v_pp },
        },
        // {
        //     path: '/packet-partner/admin/list/:godownid',
        //     name: 'pp-adm-list',
        //     component: () => import('@/views/packingPartner/admppartnerList.vue'),
        //     meta: { has_perm: perm.c_v_pp },
        // },
        // {
        //     path: '/packet-partner/edit/:ppid',
        //     name: 'edit-pp',
        //     component: () => import('@/views/packingPartner/editPartner.vue'),
        //     meta: { has_perm: perm.c_e_pp },
        // },
        // {
        //     path: '/packet-partner/assign-packets',
        //     name: 'assign-pp',
        //     component: () => import('@/views/packingPartner/assignPackToPartner.vue'),
        //     meta: { has_perm: perm.c_v_pp },
        // },
        {
            path: '/tools-sprayers/add',
            name: 'add-tools',
            component: () => import('@/views/factory/tools/addTools.vue'),
            meta: { has_perm: perm.c_a_ts },
        },
        {
            path: '/tools-sprayers/adm-list',
            name: 'adm-tools-menu',
            component: () => import('@/views/menupages/toolAdminMenu.vue'),
            meta: { has_perm: perm.c_v_ts },
        },
        {
            path: '/tools-sprayers/:godownid',
            name: 'adm-tools-list',
            component: () => import('@/views/factory/tools/admToolsList.vue'),
            meta: { has_perm: perm.c_v_ts },
        },
        {
            path: '/tools-sprayers/edit/:toolid',
            name: 'edit-tool',
            component: () => import('@/views/factory/tools/editTool.vue'),
            meta: { has_perm: perm.c_e_ts },
        },
        {
            path: '/tools-sprayers/list',
            name: 'tools-list',
            component: () => import('@/views/factory/tools/toolsList.vue'),
            meta: { has_perm: perm.c_v_ts },
        },
        {
            path: '/md-sticker/add',
            name: 'md-sticker',
            component: () => import('@/views/masterdata/stickersAdd.vue'),
            meta: { has_perm: perm.c_v_md },
        },
        {
            path: '/stickers/add',
            name: 'add-sticker',
            component: () => import('@/views/factory/stickers/addSticker.vue'),
            meta: { has_perm: perm.c_a_st },
        },
        {
            path: '/md-pots/add',
            name: 'md-pots',
            component: () => import('@/views/masterdata/potsAdd.vue'),
            meta: { has_perm: perm.c_v_md },
        },

        {
            path: '/stickers/adm-list',
            name: 'adm-stickers-menu',
            component: () => import('@/views/menupages/stickerAdminMenu.vue'),
            meta: { has_perm: perm.c_v_ts },
        },
        {
            path: '/stickers/:godownid',
            name: 'adm-stickers-list',
            component: () => import('@/views/factory/stickers/admStickerList.vue'),
            meta: { has_perm: perm.c_v_st },
        },
        {
            path: '/stickers-list',
            name: 'stickers-list',
            component: () => import('@/views/factory/stickers/listSticker.vue'),
            meta: { has_perm: perm.c_v_st },
        },
        {
            path: '/stickers/edit/:stickerid',
            name: 'edit-sticker',
            component: () => import('@/views/factory/stickers/editSticker.vue'),
            meta: { has_perm: perm.c_e_st },
        },
        {
            path: '/stickers/adm-total-list',
            name: 'adm-stickers-total-menu',
            component: () => import('@/views/menupages/stickerAdminTotalMenu.vue'),
            meta: { has_perm: perm.c_v_ts },
        },
        {
            path: '/stickers/list/:godownid',
            name: 'adm-stickers-total-list',
            component: () => import('@/views/factory/stickers/admTotalStickerList.vue'),
            meta: { has_perm: perm.c_v_st },
        },
        {
            path: '/sticker/list/',
            name: 'stickers-total-list',
            component: () => import('@/views/factory/stickers/totalStickerList.vue'),
            meta: { has_perm: perm.c_v_st },
        },
        {
            path: '/md-grow-bag/add',
            name: 'md-growbag',
            component: () => import('@/views/masterdata/growbagAdd.vue'),
            meta: { has_perm: perm.c_v_md, },
        },
        {
            path: '/md-agrocops/add',
            name: 'md-agrocops',
            component: () => import('@/views/masterdata/agrocopsAdd.vue'),
            meta: { has_perm: perm.c_v_md, },
        },
        {
            path: '/grow-bag/add',
            name: 'add-bag',
            component: () => import('@/views/factory/growbag/bagAdd.vue'),
            meta: { has_perm: perm.c_a_bg, },
        },
        {
            path: '/grow-bag/list',
            name: 'list-bag',
            component: () => import('@/views/factory/growbag/listBag.vue'),
            meta: { has_perm: perm.c_v_bg, },
        },
        {
            path: '/grow-bag/adm-menu',
            name: 'adm-bag-menu',
            component: () => import('@/views/menupages/bagAdminMenu.vue'),
            meta: { has_perm: perm.c_v_bg },
        },
        {
            path: '/grow-bag/admin-list/:godownid',
            name: 'adm-list-bag',
            component: () => import('@/views/factory/growbag/admListBag.vue'),
            meta: { has_perm: perm.c_v_bg, },
        },
        {
            path: '/grow-bag/edit/:bagid',
            name: 'edit-bag',
            component: () => import('@/views/factory/growbag/editBag.vue'),
            meta: { has_perm: perm.c_e_bg },
        },
        {
            path: '/customer/add',
            name: 'add-cust',
            component: () => import('@/views/customer/addCustomer.vue'),
            meta: { has_perm: perm.c_a_cust },
        },
        {
            path: '/customer/adm-list/menu',
            name: 'adm-cust-menu',
            component: () => import('@/views/menupages/customerAdminMenu.vue'),
            meta: { has_perm: perm.c_v_cust },
        },
        {
            path: '/customer/admin-list/:godownid',
            name: 'adm-list-cust',
            component: () => import('@/views/customer/admcustomerList.vue'),
            meta: { has_perm: perm.c_v_cust, },
        },
        {
            path: '/edit-customer/:customerid',
            name: 'edit-cust',
            component: () => import('@/views/customer/editCustomer.vue'),
            meta: { has_perm: perm.c_e_cust },
        },
        {
            path: '/list-customer',
            name: 'list-cust',
            component: () => import('@/views/customer/listCustomer.vue'),
            meta: { has_perm: perm.c_v_cust },
        },
       
        {
            path: '/inventory-menu',
            name: 'adm-inv-menu',
            component: () => import('@/views/menupages/admInventoryMenu.vue'),
            meta: { has_perm: perm.c_v_inv },
        },
        {
            path: '/inventory/admin-list/:godownid',
            name: 'adm-inv-list',
            component: () => import('@/views/inventory/admInventoryList.vue'),
            meta: { has_perm: perm.c_v_inv, },
        },
        {
            path: '/inventory/list',
            name: 'inv-list',
            component: () => import('@/views/inventory/inventoryList.vue'),
            meta: { has_perm: perm.c_v_inv, },
        },

        // {
        //     path: '/inventory/delivery-note/:deliverynoteid',
        //     name: 'del-note',
        //     component: () => import('@/views/inventory/deliveryNote.vue'),
        //     meta: { has_perm: perm.c_v_inv, },
        // },
        {
            path: '/tools-sprayers/adm-list/menu',
            name: 'adm-tool-menu',
            component: () => import('@/views/menupages/admTotalToolMenu.vue'),
            meta: { has_perm: perm.c_v_ts },
        },
        {
            path: '/tools-sprayers/admin-list/:godownid',
            name: 'adm-tot-tool',
            component: () => import('@/views/factory/tools/admTotalToolList.vue'),
            meta: { has_perm: perm.c_v_ts, },
        },
        {
            path: '/tools-sprayers/total-list',
            name: 'tot-tool',
            component: () => import('@/views/factory/tools/admTotalToolList.vue'),
            meta: { has_perm: perm.c_v_ts, },
        },
        {
            path: '/growbags-pots/adm-list/menu',
            name: 'adm-total-bag-menu',
            component: () => import('@/views/menupages/admTotalbagsMenu.vue'),
            meta: { has_perm: perm.c_v_bg },
        },
        {
            path: '/growbags-pots/admin-list/:godownid',
            name: 'adm-tot-bag',
            component: () => import('@/views/factory/growbag/admTotalBag.vue'),
            meta: { has_perm: perm.c_v_bg, },
        },
        {
            path: '/growbags-tool/list',
            name: 'tot-bag',
            component: () => import('@/views/factory/growbag/totalBagList.vue'),
            meta: { has_perm: perm.c_v_bg, },
        },
        {
            path: '/inventory/edit/:invoiceid',
            name: 'fact-edit-inv',
            component: () => import('@/views/factory/inventory/editInventory.vue'),
            meta: { has_perm: perm.c_e_inv, },
        },
        {
            path: '/inventory/edit/:invoiceid',
            name: 'out-edit-inv',
            component: () => import('@/views/outlet/inventory/editOutletInventory.vue'),
            meta: { has_perm: perm.c_e_d_s, },
        },
        
        
        ]
    },
    {
        path: '/auth',
        name: 'Auth',
        component: () =>
            import('@/layouts/AuthenticationLayout.vue'),
        meta: {
            requiresAuth: false,
            has_perm: true,
        },
        children: [{
            path: '/login/',
            name: 'login',
            component: () => import('@/views/auth/LoginPage.vue')
        },
        ]
    },
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: () => import("@/views/NotFound.vue"),
        meta: { has_perm: true, },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    // const publicPages = ['/login'];
    const authRequired = to.matched.some(record => record.meta.requiresAuth)
    const loggedIn = localStorage.getItem('stockuser');
    const hasPermission = to.matched.some(record => record.meta.has_perm)

    //console.log(authRequired);
    if (authRequired && !loggedIn) {
        const path = ''
        next('/login' + path);
    }else if (!hasPermission) {
        return next('/not-found');
    } else {
        next()
    }
})

export default router