
function currentUser() {
    const cuser = JSON.parse(localStorage.getItem('stockuser'));
    return cuser
}

function isHyzAdm(){
    let isHyzadm = false
    const cuser = JSON.parse(localStorage.getItem('stockuser'));
    if(cuser.is_hyz_admin == 1 || cuser.is_hyz_sup_admin == 1){
        isHyzadm = true
    }
    return isHyzadm
}
function isFactoryAdm(){
    let isFactoryadm = false
    const cuser = JSON.parse(localStorage.getItem('stockuser'));
    if(cuser.is_factory_admin == 1 || cuser.is_factory_sup_admin == 1){
        isFactoryadm = true
    }
    return isFactoryadm
}
function isOutletAdm(){
    let isOutletadm = false
    const cuser = JSON.parse(localStorage.getItem('stockuser'));
    if(cuser.is_outlet_admin == 1 || cuser.is_outlet_sup_admin == 1){
        isOutletadm = true
    }
    return isOutletadm
}
function isExecutiveAdm(){
    let isExeadm = false
    const cuser = JSON.parse(localStorage.getItem('stockuser'));
    if(cuser.is_executive == 1){
        isExeadm = true
    }
    return isExeadm
}
function isPpAdm(){
    let isPpadm = false
    const cuser = JSON.parse(localStorage.getItem('stockuser'));
    if(cuser.is_pp == 1){
        isPpadm = true
    }
    return isPpadm
}
export const uzr = {
    currentUser,isHyzAdm,isFactoryAdm,isOutletAdm,isExecutiveAdm,isPpAdm,
};
